import React, { useEffect, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { selectActivePage } from '../features/ui/uiSlice'
import { tabletMinWidth } from '../mixins';


const StyledBar = styled.div`
    height: 6px;
    display: flex;
    margin-bottom: 26px;
    
    @media screen and (min-width: ${tabletMinWidth}px) {
        margin-bottom: 100px;
    }
`;

const StyledBarItem = styled.div`
    flex-grow: 1;
    border-radius: 14px;
    background-color: ${({isActive}) => isActive ? '#F58A3C' : '#F0EFEF'};
    transition: background-color 300ms;
    
    &:not(:first-child) {
        margin-left: 5px;
    }
`;

const ProgrBar = () => {
    const activePage = useSelector(selectActivePage);
    const [showBar,setShowBar] = useState(false);

    const pagesWithProgressBar = useMemo(() => {
        return [
            'check-ins',
            'platform-support',
            'ar-search',
            'sos-button'
        ]
    },[]);

    const activePageIndex = useMemo(() => pagesWithProgressBar.indexOf(activePage), [activePage, pagesWithProgressBar]);

    useEffect(() => {
        setShowBar(activePageIndex >= 0);
    }, [activePageIndex]);

    const renderProgress = (item,i) => {
        const isActive = (i <= activePageIndex);

        return <StyledBarItem isActive={isActive} key={i}/>
    };

    if (!showBar) return null;

    return <StyledBar>{pagesWithProgressBar.map(renderProgress)}</StyledBar>;
};

export default ProgrBar
