export const PLANS = {
  "fm-monthly-intro7d-9.99-29.99": {
    name: "fm-monthly-intro7d-9.99-29.99",
    price: 29.99,
    periodPrice: 9.99,
    description: "1_week_trial",
    disclaimer: "monthly",
    productId: "1 month - $29.99",
    duration: "1 month",
  },
  "fm-monthly-19.99": {
    name: "fm-monthly-19.99",
    price: 19.99,
    periodPrice: 19.99,
    description: "monthly",
    disclaimer: "monthly",
    productId: "1 month - $19.99",
    duration: "1 month",
  },
  "fm-3month-39.99": {
    name: "fm-3month-39.99",
    price: 39.99,
    periodPrice: 39.99,
    description: "quarterly",
    disclaimer: "quarterly",
    productId: "3 months - $39.99",
    duration: "3 months",
  },
};
