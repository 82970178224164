import { createSlice } from '@reduxjs/toolkit'

export const plansSlice = createSlice({
  name: 'plans',
  initialState: {
    showCheckout: false,
    defaultPlanId: '',
    landingType: 'fullPrice',
    plan: '',
    planData: {},
  },
  reducers: {
    setDefaultPlanId: (state, action) => {
      state.defaultPlanId = action.payload
    },
    setShowCheckout: (state, action) => {
      state.showCheckout = action.payload
    },
    setLandingType: (state, action) => {
      state.landingType = action.payload || 'fullPrice'
    },
    setPlan: (state, action) => {
      state.plan = action.payload
    },
    setPlanData: (state, action) => {
      state.planData = action.payload
    }
  },
})

export const { setPlan, setLandingType, setPlanData, setShowCheckout, setDefaultPlanId } = plansSlice.actions

export const selectShowCheckout = (state) => state.plans.showCheckout
export const selectDefaultPlanId = (state) => state.plans.defaultPlanId
export const selectPlan = (state) => state.plans.plan
export const selectLandingType = (state) => state.plans.landingType
export const selectPlanData = (state) => state.plans.planData

export default plansSlice.reducer
