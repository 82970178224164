import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { setEmail } from './signupSlice'
import styled from 'styled-components'
import useDebounce from '../../helper/useDebounce'
import { wh, fontSize } from '../../mixins'
// eslint-disable-next-line
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/


const isArabic = navigator.language.startsWith('ar');
const textAlign = isArabic ? 'right' : 'left';

const Input = styled.input`
    ${wh('100%', '56px')};
    border-radius: 24px;
    border: none;
    ${({error}) => error && 'box-shadow: 0 0 0 1px #FF5B5B;'}
    background: #FFFFFF;
    padding: 15px;
    ${fontSize(16)};
    line-height: 26px;
    color: #323232;
    text-align: ${textAlign};
    
    &::placeholder {
        color: #494E57;
    }
    
    &:focus,
    &:active {
        outline: none;
        border: none;
    }
`;

const DomainBtn = styled.div`
    ${wh('90px', '28px')};
    min-width: 90px;
    background: #ffffff;
    border: 1px solid #F58A3C;
    border-radius: 10px;
    ${fontSize(12)};
    line-height: 28px;
    color: #848484;
    padding: 0 5px;
    
    &:not(:first-child) {
        margin-left: 9px;
    }
    
    @media screen and (max-width: 350px) {
        &:not(:first-child) {
            margin-left: 5px;
        }
    }
`;

const BtnContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: ${isArabic ? 'row-reverse' : 'row'};
    margin-top: 13px;
    min-height: 28px;
`;

const SignupInput = ({setFocused}) => {
    const [value, setValue] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [selected, setSelected] = useState(null);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const debouncedEmail = useDebounce(value, 200);
    const DOMAINS = useMemo(() => ['gmail.com', 'yahoo.com', 'hotmail.com'], []);

    useEffect(() => {
        if (debouncedEmail) {
            dispatch(setEmail({ email: debouncedEmail, isValid }));
        }
    }, [debouncedEmail, dispatch, isValid]);

    useEffect(() => {
        let isEmailValid = EMAIL_REGEX.test(value);
        setIsValid(isEmailValid);

        if (isEmailValid) {
            let currentDomain = value.split('@')[1];
            setSelected(DOMAINS.indexOf(currentDomain) !== -1 ? currentDomain : null)
        }
    }, [value, DOMAINS]);

    const renderDomains = useCallback(() => {
        const onAddDomainClick = (domain) => {
            if (!value) return;
            setValue((prevState) => (prevState.split('@')[0] += domain));
            setSelected(domain)
        };

        const renderDomainItem = (item,index) => (
            <DomainBtn key={item}
                       disabled={selected}
                       onClick={() => onAddDomainClick('@' + item)}
                       id={'domain-button-'+(index+1)}
            >
                @{item}
            </DomainBtn>
        );

        if (value && selected) return DOMAINS.filter((i) => i === selected).map(renderDomainItem);

        return DOMAINS.map(renderDomainItem)
    }, [value, selected, DOMAINS]);

    return (
        <>
            <Input type="email"
                   error={value && !isValid}
                   autoComplete="email"
                   value={value}
                   onChange={(evt) => setValue(evt.target.value)}
                   onFocus={() => setFocused(true)}
                   onBlur={() => setFocused(false)}
                   placeholder={t('email')}
                   id={'sign-up-input'}
            />
            <BtnContainer>{renderDomains()}</BtnContainer>
        </>
    )
};

export default SignupInput
