import React, { useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import { fontSize, tabletMinWidth, wh } from "../mixins";
import { useTranslation } from "react-i18next";
import UIButton from "./UIButton";

const isStage = process.env.REACT_APP_ENV === "stage";
const isArabic = navigator.language.startsWith("ar");
const textAlign = isArabic ? "right" : "left";

const ProgressWrapper = styled.div`
  ${wh("132px")};
  margin: 20px auto;
  box-shadow: inset 0 0 0 6px #f2f2f2;
  border-radius: 50%;
  position: relative;

  @media screen and (min-width: ${tabletMinWidth}px) {
    margin: 48px auto;
  }
`;

const StyledSvg = styled.svg`
  stroke: #f58a3c;
  stroke-linecap: round;
  ${wh("100%")};
  position: absolute;
  top: calc(50% - 66px);
  left: calc(50% - 66px);
  transform: rotate(-90deg);

  circle {
    transition: stroke-dashoffset 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    stroke-dasharray: 132px;
    ${({ strokeDashoffset }) => `
            stroke-dashoffset: ${strokeDashoffset}px;
        `}
  }
`;

const StyledPercentage = styled.p`
  font-weight: 600;
  ${fontSize(32)};
  line-height: 38px;
  color: #323232;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
`;

const StyledList = styled.div`
  @media screen and (min-width: ${tabletMinWidth}px) {
    margin-bottom: 8px;
  }

  ul {
    list-style: none;
    margin: 0 auto;
    padding: 0;
    width: fit-content;

    li {
      font-weight: 400;
      ${fontSize(16)};
      line-height: 20px;
      color: #323232;
      display: flex;
      flex-direction: ${isArabic ? "row-reverse" : "row"};
      justify-content: flex-start;
      align-items: center;
      min-width: 260px;

      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }
`;

const StyledStatus = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    ${wh("20px")};
    border-radius: 50%;
    background-color: ${({ isActive }) =>
      isActive ? "#F58A3C" : "transparent"};
    border: 2px solid ${({ isActive }) => (isActive ? "#F58A3C" : "#E0E0E0")};
    position: relative;
    color: #fff;
    margin-right: 10px;
    margin-left: 10px;
    margin-${textAlign}: 0;
    transition: all 0.1s linear;
    transition-property: background-color, border-color;
`;

const CreatingProfileUI = ({ doneCallback }) => {
  const { t } = useTranslation();
  const [progress, setProgress] = useState(0);
  const buttonDisabled = progress < 100;

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 100 : prevProgress + 1
      );
    }, 80);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const onPercentClick = () => {
    isStage && doneCallback();
  };

  const LIST = useMemo(() => {
    return [
      {
        percentage: 25,
        text: t("checking_current_location"),
      },
      {
        percentage: 50,
        text: t("adding_place_alerts"),
      },
      {
        percentage: 75,
        text: t("safety_features"),
      },
      {
        percentage: 95,
        text: t("activating_location_history"),
      },
    ];
  }, [t]);

  const renderItem = (item, index) => {
    const { percentage, text } = item;
    const isActive = progress >= percentage;
    const first = index === 0;
    return (
      <li
        onClick={() => {
          if (first) onPercentClick();
        }}
        id={first ? "test-creating-profile-skip" : null}
        key={index}
      >
        <StyledStatus isActive={isActive}>
          <svg
            width="10"
            height="8"
            viewBox="0 0 10 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.29535 1.60543L4.72735 7.60543C4.53935 7.85243 4.24835 7.99843 3.93835 8.00043H3.93135C3.62435 8.00043 3.33435 7.85843 3.14435 7.61643L0.712354 4.50943C0.372354 4.07543 0.448354 3.44643 0.883354 3.10643C1.31735 2.76543 1.94735 2.84143 2.28735 3.27743L3.92035 5.36343L7.70435 0.394429C8.03835 -0.0445709 8.66535 -0.130571 9.10635 0.204429C9.54535 0.539429 9.63035 1.16643 9.29535 1.60543"
              fill="white"
            />
          </svg>
        </StyledStatus>
        <span>{text}</span>
      </li>
    );
  };

  return (
    <>
      <div>
        <ProgressWrapper>
          <StyledPercentage>{`${progress}%`}</StyledPercentage>
          <StyledSvg
            viewBox="22 22 44 44"
            xmlns="http://www.w3.org/2000/svg"
            strokeDasharray={132}
            strokeDashoffset={132 - 1.32 * progress}
          >
            <circle cx="44" cy="44" r="21" fill="none" strokeWidth="2" />
          </StyledSvg>
        </ProgressWrapper>
        <StyledList>
          <ul>{LIST.map(renderItem)}</ul>
        </StyledList>
      </div>
      <UIButton
        onClick={() => {
          doneCallback();
        }}
        disabled={buttonDisabled}
      />
    </>
  );
};

export default CreatingProfileUI;
