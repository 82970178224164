import { createSlice } from '@reduxjs/toolkit'

export const familySlice = createSlice({
  name: 'family',
  initialState: {
    name: '',
    target: '',
    place: '',
  },
  reducers: {
    setName: (state, action) => {
      const value = action.payload;
      const formattedValue = value.charAt(0).toUpperCase() + value.slice(1);
      state.name = encodeURIComponent(formattedValue.trim())
    },
    setTarget: (state, action) => {
      state.target = action.payload
    },
    setPlace: (state, action) => {
      state.place = action.payload
    },
  },
});

export const { setName, setTarget, setPlace } = familySlice.actions;

export const selectName = (state) => state.family.name;
export const selectTarget = (state) => state.family.target;
export const selectPlace = (state) => state.family.place;

export default familySlice.reducer
