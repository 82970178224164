import React, {useEffect, useMemo} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import styled from 'styled-components'
import {selectLandingType, selectPlan, setDefaultPlanId} from './plansSlice';
import { selectName } from '../family/familySlice'
import {flexButton, fontSize, wh} from '../../mixins';
import MainHeadline from '../../UI/MainHeadline';
import MainDescription from '../../UI/MainDescription';
import Timer from '../../UI/Timer'

const isArabic = navigator.language.startsWith('ar');
const textAlign = isArabic ? 'right' : 'left';

const NameWrapper = styled.span`
    color: #FFA361;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const StyledList = styled.ul`
    list-style: none;
`;

const PlanBlock = styled.li`
    ${wh('100%', '74px')};
    padding: 5px 5px 5px 14px;
    display: flex;
    flex-direction: row${isArabic ? '-reverse' : ''};
    justify-content: space-between;
    align-items: center;
    background: ${({ active }) => (active ? 'linear-gradient(92.44deg, #F58A3C -1.48%, #EB5C26 102.35%)' : '#fff')};
    ${({ active }) => (!active && 'box-shadow: inset 0 0 0 1px #D4D4D4;')}
    color: ${({ active }) => (active ? '#fff' : '#909090')};
    border-radius: 16px;
    position: relative;
    
    &:not(:last-child) {
        margin-bottom: 16px;
    }
`;

const StyledPeriod = styled.div`
    display: flex;
    flex-direction: row${isArabic ? '-reverse' : ''};
    justify-content: center;
    align-items: center;
`;

const PlanTitle = styled.div`
    font-weight: 400;
    ${fontSize(16)};
    line-height: 19px;
    // letter-spacing: -0.2px;
    text-transform: uppercase;
`;

const CheckIcon = styled.div`
    ${flexButton('20px')};
    border-radius: 50%;
    margin-right: 14px;
    margin-left: 14px;
    margin-${textAlign}: 0;
    background: #fff;
    
    ${({ active }) => !active && `
        box-shadow: inset 0 0 0 1px #ADADAD;
        
        svg {
            opacity: 0;
        }
      
    `}
`;

const StyledPrice = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 12px;
    padding: 10px;
    text-align: center;
    align-self: ${({withLabel}) => withLabel ? 'flex-end' : 'stretch'};
`;

const OldPrice = styled.div`
    font-weight: 600;
    ${fontSize(12)};
    line-height: 16px;
    color: #FDAE75;
    margin-bottom: 2px;
    position: relative;
       
    &:after {
        content: '';
        display: block;
        ${wh('100%', '1px')};
        background: #ff7c7c;
        position: absolute;
        top: 50%;
        left: 0;
    }
`;

const Price = styled.div`
    font-weight: 700;
    ${fontSize(16)};
    line-height: 22px;
    letter-spacing: -0.2px;
    color: ${({ active }) => active ? '#ED612A' : '#919191'};
`;

const PerWeek = styled.div`
    font-weight: 600;
    ${fontSize(8)};
    color: ${({ active }) => active ? '#ED622B' : '#919191'};
`;

const Label = styled.div`
    position: absolute;
    top: 0;
    background: #FFFFFF;
    border: 1px solid #EC6029;
    box-shadow: 0px 4px 11px rgba(0,0,0,.25);
    border-radius: 7px;
    padding: 5px 8px;
    font-weight: 600;
    ${fontSize(12)};
    line-height: 14px;
    letter-spacing: -0.2px;
    text-transform: uppercase;
    transform: translateY(-50%);
    color: #ED612A;
`;

const PopularLabel = styled(Label)`
    ${({withLabel}) => withLabel
        ? `
            ${textAlign}: 6px;
        `
        : `
            left: 50%;
            transform: translate(-50%,-50%);
        `
    }
`;

const SaveLabel = styled(Label)`
    right: 6px;
    left: 6px;
    ${textAlign}: auto;
`;

const DefaultPlans = ({handleSetPlan}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const landingType = useSelector(selectLandingType);
    const plan = useSelector(selectPlan);
    const name = useSelector(selectName);

    const OFFERS = useMemo(() => {
        return {
            fullPrice: [
                {
                    id: 'fm-monthly-intro7d-9.99-29.99',
                    title: t('1_week_trial'),
                    price: '$9.99*',
                    popular: true,
                },
                {
                    id: 'fm-monthly-19.99',
                    title: t('1_month'),
                    price: '$19.99',
                    oldPrice: '$29.99',
                },
                {
                    id: 'fm-3month-39.99',
                    title: t('3_months'),
                    price: '$39.99',
                    oldPrice: '$49.99',
                },
            ]
        }
    }, [t]);

    const currentPlans = useMemo(() => OFFERS[landingType], [landingType, OFFERS]);

    useEffect(() => {
        handleSetPlan(currentPlans[0].id)
        dispatch(setDefaultPlanId(currentPlans[0].id))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderOffer = (planInfo,index) => {
        const {id,title,price,week,oldPrice,popular,save} = planInfo;
        const withSaveLabel = !!save;
        const isActive = (plan === id);

        return (
            <PlanBlock active={isActive} onClick={() => handleSetPlan(id)} id={'plan-button-'+(index+1)} key={id}>
                <StyledPeriod>
                    <CheckIcon active={isActive}>
                        <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M8.79548 1.60543L4.22748 7.60543C4.03948 7.85243 3.74848 7.99843 3.43848 8.00043H3.43148C3.12448 8.00043 2.83448 7.85843 2.64448 7.61643L0.212477 4.50943C-0.127523 4.07543 -0.0515235 3.44643 0.383477 3.10643C0.817477 2.76543 1.44748 2.84143 1.78748 3.27743L3.42048 5.36343L7.20448 0.394429C7.53848 -0.0445709 8.16548 -0.130571 8.60648 0.204429C9.04548 0.539429 9.13048 1.16643 8.79548 1.60543Z"
                                  fill="#FFA361"/>
                        </svg>
                    </CheckIcon>
                    <PlanTitle>{title}</PlanTitle>
                </StyledPeriod>
                <StyledPrice withLabel={withSaveLabel}>
                    {oldPrice && <OldPrice>{oldPrice}</OldPrice>}
                    <Price active={isActive}>{price}</Price>
                    {week && <PerWeek active={isActive}>{week}</PerWeek>}
                </StyledPrice>
                {popular && isActive && <PopularLabel withLabel={withSaveLabel}>{t('most_popular')}</PopularLabel>}
                {save && isActive && <SaveLabel>{t('save_76')}</SaveLabel>}
            </PlanBlock>
        )
    };

    return (
        <>
            <MainHeadline customStyles={'display:flex;justify-content:center;'}>{t('hey')} <NameWrapper>{decodeURIComponent(name)}!</NameWrapper></MainHeadline>
            <MainDescription>{t('best_program')}</MainDescription>
            <Timer />
            <StyledList>{currentPlans.map(renderOffer)}</StyledList>
        </>
    )
};

export default DefaultPlans
