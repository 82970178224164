import { createSlice } from '@reduxjs/toolkit';

export const uiSlice = createSlice({
    name: 'ui',
    initialState: {
        activePage: null,
    },
    reducers: {
        setActivePage: (state, action) => {
            state.activePage = action.payload;
        },
    },
});

export const { setActivePage } = uiSlice.actions;

export const selectActivePage = state => state.ui.activePage;

export default uiSlice.reducer;