import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { selectLoader } from '../features/loader/loaderSlice';
import { keyframes } from 'styled-components';
import { wh, fontSize } from '../mixins';


const spinning = keyframes`
    to {
        transform: rotate(360deg);
    }
`;

const StyledSpinner = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    background: #fff;
    ${wh('100%')};
    position: fixed;
    z-index: 1051;
    
    &:before {
        content: '';
        display: block;
        ${wh('48px')};
        animation: ${spinning} 1.5s linear infinite;
        background: url('../assets/spinner-image.svg') center / 100% no-repeat;
    }
`;

const Message = styled.span`
    color: #323232;
    ${fontSize(16)};
    line-height: 19px;
    text-align: center;
    white-space: break-spaces;
    margin-top: 32px;
`;


const Loader = ({showMessage}) => {
    const {t} = useTranslation();
    const storeShow = useSelector(selectLoader);

    if (storeShow) return <StyledSpinner id={'loading-element'}>{showMessage && <Message>{t('loading_text')}</Message>}</StyledSpinner>;

    return null;
};

export default Loader;