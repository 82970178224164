import styled from 'styled-components';
import { fontSize } from '../mixins';


const Headline = styled.p`
    ${fontSize(16)};
    line-height: 19px;
    letter-spacing: -0.005em;
    color: #323232;
    margin: ${({customMargin}) => customMargin || 0};
    white-space: pre-line;
`;

const MainDescription = ({children, customMargin}) => <Headline customMargin={customMargin}>{children}</Headline>;

export default MainDescription;