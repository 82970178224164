import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components';
import { PLANS } from '../../analytics/eCommerce'
import {
  selectLandingType,
  selectPlan,
  setPlan,
  setPlanData,
  setShowCheckout,
} from './plansSlice'
import { appendLinksList } from '../../helper/utils'
import DefaultPlans from './DefaultPlans'
import {
  Forbes,
  Mashable,
  TC,
  TechCrunch,
} from './logos'
import { fontSize, wh } from '../../mixins'
import UIButton from '../../UI/UIButton'


const isArabic = navigator.language.startsWith('ar');
const textAlign = isArabic ? 'right' : 'left';

const TrialHint = styled.div`
  ${fontSize(12)};
  line-height: 14px;
  color: #818181;
  margin-top: 16px;
  transition: opacity 200ms;
  opacity: ${({ show }) => (show ? 1 : 0)};
`;

const FreeTrialWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  & > * {
    width: 100%;
  }
`;

const SecondaryTitle = styled.h1`
  font-weight: 700;
  ${fontSize(24)};
  line-height: 29px;
  color: #323232;
  text-align: center;
  letter-spacing: -0.2px;
  margin: ${({customMargin}) => customMargin || 0};
`;

const StyledList = styled.ul`
  list-style: none;
`;

const StyledItem = styled.li`
  position: relative;
  padding-${textAlign}: 20px;
  font-weight: 400;
  ${fontSize(16)};
  line-height: 19px;
  color: #323232;
  text-align: ${textAlign};

  svg {
    ${wh('9px','8px')};
    position: absolute;
    top: calc(50% - 4px);
    ${textAlign}: 0;
  }
  
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const PressLogos = styled.div`
  display: flex;
  flex-direction: revert;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 48px;

  svg {
    flex: 1 0 40%;
    
    &:nth-child(-n+2) {
      margin-bottom: 20px;
    }
    &:nth-child(odd) {
      margin-right: 20px;
    }
  }
`;

const ReviewItem = styled.div`
  background: #FFFFFF;
  box-shadow: 4px 6px 20px rgba(0,0,0,.12);
  border-radius: 12px;
  padding: 12px 16px;
  
  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const ReviewHead = styled.div`
  height: 15px;
  display: flex;
  flex-direction: row${isArabic ? '-reverse' : ''};
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 8px;
`;

const ReviewBody = styled.div`
  display: flex;
  flex-direction: row${isArabic ? '-reverse' : ''};
  align-items: center;
  margin-bottom: 8px;
`;

const ReviewerPhoto = styled.img`
  ${wh('30px')};
  margin-right: 8px;
`;

const ReviewerName = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #323232;
`;

const AppIcon = styled.div`
  ${wh('15px')};
  background-image: url('../assets/app-icon.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 7px;
`;

const Rate = styled.div`
  ${wh('53px', '10px')};
  background-image: url('../assets/stars.png');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
`;

const MarketIcon = styled.div`
  flex-grow: 1;
  height: 100%;
  background-image: url('../assets/${({market}) => market}-icon.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right center;
`;

const ReviewText = styled.div`
  font-weight: 400;
  ${fontSize(14)};
  line-height: 17px;
  letter-spacing: -0.24px;
  text-align: ${textAlign};
`;

const GuaranteeBlock = styled.div`
  background: #FFFAF7;
  border: 3px solid #F58A3C;
  border-radius: 12px;
  padding: 40px 19px 19px;
  margin: 45px 0;
  position: relative;

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: -33px;
    left: calc(50% - 30px);
  }
  
  &:before {
    background-color: #fff;
    ${wh('60px')};
    box-sizing: border-box;
    border-radius: 50%;
    border: inherit;
    border-top-color: transparent;
    border-right-color: transparent;
    transform: rotate(-45deg);
  }
  
  &:after {
    ${wh('60px')};
    background: url('../assets/guarantee-icon.svg') center no-repeat;
  }
`;

const GuaranteeTitle = styled.div`
  font-weight: 700;
  ${fontSize(24)};
  line-height: 31px;
  letter-spacing: -0.2px;
  color: #F58A3C;
  text-align: center;
  white-space: break-spaces;
  margin-bottom: 12px;
`;

const GuaranteeText = styled.div`
  ${fontSize(14)};
  line-height: 24px;
  color: #909090;
  text-align: left;
`;

const PlansScreen = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const plan = useSelector(selectPlan);
  const landingType = useSelector(selectLandingType);
  const showTrialHint = plan === 'fm-monthly-intro7d-9.99-29.99';

  useEffect(() => {
    appendLinksList('checkout');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const handleSetPlan = (id) => dispatch(setPlan(id));

  const defaultPlan = <DefaultPlans handleSetPlan={handleSetPlan} />;

  const plansComponents = {
    'fullPrice': defaultPlan,
  };

  const FEATURES = useMemo(() => {
    return [
      t('know_when_family_come_or_go'),
      t('realtime_location_sharing'),
      t('be_notified_in_case'),
      t('see_the_history'),
      t('notify_your_family'),
    ]
  },[t]);

  const renderFeature = (feature, index) => {
    return (
      <StyledItem key={index}>
        <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M8.79548 1.60543L4.22748 7.60543C4.03948 7.85243 3.74848 7.99843 3.43848 8.00043H3.43148C3.12448 8.00043 2.83448 7.85843 2.64448 7.61643L0.212477 4.50943C-0.127523 4.07543 -0.0515235 3.44643 0.383477 3.10643C0.817477 2.76543 1.44748 2.84143 1.78748 3.27743L3.42048 5.36343L7.20448 0.394429C7.53848 -0.0445709 8.16548 -0.130571 8.60648 0.204429C9.04548 0.539429 9.13048 1.16643 8.79548 1.60543Z" fill="#FFA361"/>
        </svg>
        {feature}
      </StyledItem>
    )
  };

  const REVIEWS = useMemo(() => {
    return [
      {
        market: 'apple',
        reviewer: 'John Eydman',
        reviewerSource: '../assets/reviewer1.png',
        text: t('review_1'),
      },
      {
        market: 'apple',
        reviewer: 'Chris Topper',
        reviewerSource: '../assets/reviewer2.png',
        text: t('review_2'),
      },
      {
        market: 'android',
        reviewer: 'Jane Stevenson',
        reviewerSource: '../assets/reviewer3.png',
        text: t('review_3'),
      },
    ];
  }, [t]);

  const renderReview = (rev, index) => {
    return (
      <ReviewItem key={index}>
        <ReviewHead>
          <AppIcon />
          <Rate />
          <MarketIcon market={rev.market} />
        </ReviewHead>
        <ReviewBody>
          <ReviewerPhoto src={rev.reviewerSource} alt={rev.reviewer}/>
          <ReviewerName>{rev.reviewer}</ReviewerName>
        </ReviewBody>
        <ReviewText>{rev.text}</ReviewText>
      </ReviewItem>
    )
  };

  const planDetails = useMemo(() => PLANS[plan],[plan]);

  const onContinueClick = () => {
    const { duration, description, periodPrice: price } = planDetails;
    let planData = {
      Duration: duration,
      Value: price,
      description: description,
      periodPrice: price,
    };
    dispatch(setPlanData(planData));
    dispatch(setShowCheckout(true))
  };

  const continueButton = <UIButton onClick={onContinueClick} />;

  const trialHint = planDetails && (
    <TrialHint show={showTrialHint}>
      {t('trial_hint', {now: planDetails.periodPrice, then: `$${planDetails.price}/ ${planDetails.duration}`})}
    </TrialHint>
  );

  const onTermsClick = e => {
    if (e.target.id !== 'terms-link') return;
    window.open('https://www.familo.net/en/assets/Terms_and_Conditions_Familonet.pdf');
  };

  return (
    <>
      {plansComponents[landingType]}
      <FreeTrialWrapper >
        <div>
          {continueButton}
          {/*{trialHint}*/}
        </div>
        <div>
          <SecondaryTitle customMargin={'24px 0 12px'} >{t('what_you_get')}</SecondaryTitle>
          <StyledList>{FEATURES.map(renderFeature)}</StyledList>
        </div>
      </FreeTrialWrapper>
      <GuaranteeBlock>
        <GuaranteeTitle>{t('guarantee_title')}</GuaranteeTitle>
        <GuaranteeText dangerouslySetInnerHTML={{__html: t('guarantee_text')}} onClick={e => onTermsClick(e)} />
      </GuaranteeBlock>
      <SecondaryTitle customMargin={'0 0 20px'} >{t('write_about_us')}</SecondaryTitle>
      <PressLogos>
        {Forbes}
        {Mashable}
        {TC}
        {TechCrunch}
      </PressLogos>
      <SecondaryTitle customMargin={'0 0 16px'} >{t('customer_reviews')}</SecondaryTitle>
      {REVIEWS.map(renderReview)}
      {continueButton}
      {trialHint}
    </>
  )
};

export default PlansScreen
