import i18next from 'i18next'
import {initReactI18next} from 'react-i18next'
import en from './locales/en.json';


i18next
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        lng: navigator.language || navigator.userLanguage,
        resources: {
            en: {translation: en},
        },
        interpolation: {
            escapeValue: false
        },
        wait: true
    });

export default i18next;

