import React from 'react'


export const Logo = ({invert}) => {
  return (
      <svg width="63" height="82" viewBox="0 0 63 82" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M23.8688 0.9461C7.001 5.16843 -3.26384 22.3157 0.942833 39.2463C5.14784 56.1718 34.137 83.5359 43.8626 81.4105C53.5832 79.2846 66.2437 40.8874 62.0326 23.9546C57.8315 7.02799 40.7421 -3.27567 23.8688 0.9461Z" fill={invert ? '#fff' : "url(#paint0_linear_3425_6960)"} />
          <path fillRule="evenodd" clipRule="evenodd" d="M34.8167 45.0098C27.4346 46.856 19.9609 42.3517 18.12 34.9396C16.2779 27.532 20.7761 20.0314 28.1521 18.1813C35.5386 16.3356 43.0135 20.8444 44.8527 28.2481C46.6909 35.6579 42.2055 43.1642 34.8167 45.0098V45.0098Z" fill={invert ? '#F27339' : "white"} />
          <defs>
              <linearGradient id="paint0_linear_3425_6960" x1="31.4322" y1="0" x2="31.4322" y2="81.5273" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#FFA361"/>
                  <stop offset="1" stopColor="#EB5B25"/>
              </linearGradient>
          </defs>
      </svg>
  )
};
