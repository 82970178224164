import styled from "styled-components";
import { useSelector } from "react-redux";
import { selectShowCheckout } from "../plans/plansSlice";
import withStripeInit from "../../hoc/withStripeInit";
import CheckoutScreen from "../checkout/CheckoutScreen";
import PlansScreen from "../plans/PlansScreen";

const CheckoutContainer = styled.div`
  display: ${({ showCheckout }) => (showCheckout ? "block" : "none")};
`;

const PlanContainer = styled.div`
  display: ${({ showCheckout }) => (showCheckout ? "none" : "block")};
`;

const PlanCheckout = ({ history, stripePromise }) => {
  const showCheckout = useSelector(selectShowCheckout)

  return (
    <div>
      <PlanContainer showCheckout={showCheckout}>
        <PlansScreen history={history}/>
      </PlanContainer>
      <CheckoutContainer showCheckout={showCheckout}>
        <CheckoutScreen
          history={history}
          stripePromise={stripePromise}
        />
      </CheckoutContainer>
    </div>
  );
};

export default withStripeInit(PlanCheckout);
