import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { appendLink } from "../../helper/utils";
import { PLANS } from "../../analytics/eCommerce";
import Stripe from "./Stripe";
import { PaymentRequestButtonElement, Elements } from "@stripe/react-stripe-js";
import {
  selectName,
  selectFormValidity,
  selectSuccess,
  sendMainPurchaseRequest,
  selectShowPaymentError,
  setCardholderName,
  selectIfCardFormShown,
  setIfCardFormShown,
} from "./checkoutSlice";
import {
  selectToken,
  selectUserUuid,
} from "../signup/signupSlice";
import {
  selectDefaultPlanId,
  selectPlan,
  setPlan,
  setShowCheckout,
  selectShowCheckout,
} from "../plans/plansSlice";
import { fontSize } from "../../mixins";
import ErrorScreen from "./ErrorScreen";
import MainHeadline from "../../UI/MainHeadline";
import UIButton from "../../UI/UIButton";

const isStage = process.env.REACT_APP_ENV === "stage";
const textAlign = navigator.language.startsWith("ar") ? "right" : "left";

const BackButton = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  display: flex;
  align-items: center;
  justify-items: center;
  height: 40px;
  outline: 0;
  border: unset;
  padding: 0;
  background: unset;
  cursor: pointer;
`;

const PaymentRequestContainer = styled.div`
  margin-bottom: 24px;

  div iframe {
    border-radius: 12px;
  }
`;

const CardIconsList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
`;

const style = {
  base: {
    color: "#323232",
    fontSize: "16px",
    lineHeight: "22px",
    fontWeight: 300,
    fontFamily: "Open Sans, sans-serif",
    textAlign: textAlign,

    "::placeholder": {
      color: "#909090",
      letterSpacing: "1.2px",
      fontSize: "16px",
      lineHeight: "22px",
    },
  },
};

const StoresWrapper = styled.div`
  background: #ffffff;
  box-shadow: 4px 6px 20px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  margin-top: 16px;
  padding: 19px;
`;

const Disclaimer = styled.div`
  ${fontSize(14)};
  font-weight: 400;
  line-height: 24px;
  color: #909090;
  text-align: left;

  & > span {
    text-decoration: underline;
  }
`;

const StoreButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  position: relative;

  &:after {
    content: "";
    display: block;
    width: 2px;
    height: 44px;
    background-color: #e8e8e8;
    position: absolute;
    top: calc(50% - 22px);
    left: calc(50% - 1px);
  }
`;

const Store = styled.div`
  padding-top: 34px;
  background-image: url(${({ img }) => img});
  background-repeat: no-repeat;
  background-position: center top;
`;

const StoreRating = styled.div`
  ${fontSize(16)};
  line-height: 19px;
  color: #323232;
`;

const Stars = styled.div`
  width: 120px;
  height: 20px;
  background: url("../assets/store-stars.png") center / contain no-repeat;
  margin: 4px auto 12px;
`;

const StoreRatingsCount = styled.div`
  ${fontSize(16)};
  line-height: 19px;
  color: #323232;
  margin-top: 12px;
`;

const CheckoutScreen = ({ history, stripePromise }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const showError = useSelector(selectShowPaymentError);
  const isFormValid = useSelector(selectFormValidity);
  const isSuccess = useSelector(selectSuccess);
  const [planDetails, setPlanDetails] = useState(null);
  const token = useSelector(selectToken);
  const name = useSelector(selectName);
  const plan = useSelector(selectPlan);
  const [load, setLoad] = useState(false);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const stripe = useRef();
  const card = useRef();
  const expiration = useRef();
  const cvc = useRef();
  const cardholder = useRef(null);
  const isFreeTrialPlan = plan === "fm-monthly-free3d-29.99";
  const userUuid = useSelector(selectUserUuid);
  const defaultPlanId = useSelector(selectDefaultPlanId)
  const showCheckout = useSelector(selectShowCheckout);
  const cardFormVisited = useSelector(selectIfCardFormShown);

  const CARD_BRANDS = ["visa", "mastercard", "amex", "discover"];

  const renderBrand = (card) => {
    if (!card || !CARD_BRANDS.includes(card)) return null;
    return (
      <img
        key={card}
        src={`../assets/card-brands/${card}.png`}
        alt={card}
        width={57}
        height={35}
      />
    );
  };

  useEffect(() => {
    if (load && showCheckout && !showError) {
      dispatch(setIfCardFormShown(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [load, showCheckout, showError]);

  useEffect(() => {
    appendLink("./assets/qr_code.png");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (plan) {
      setPlanDetails(PLANS[plan]);
    }
  }, [plan, dispatch, history]);

  useEffect(() => {
    if (stripePromise) {
      stripePromise.then((stripeObj) => {
        stripe.current = stripeObj;
        const elements = stripeObj.elements();


        card.current = elements.create('cardNumber', {
          style: style,
          placeholder: '0000 0000 0000 0000',
        });
        expiration.current = elements.create('cardExpiry', {
          style: style,
          placeholder: t('MM/YY'),
        });
        cvc.current = elements.create('cardCvc', {
          style: style,
          placeholder: t('CVV'),
        });

        setLoad(true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripePromise]);

  useEffect(() => {
    if (stripe.current) {
      setPaymentRequest(null)
        const pr = stripe.current.paymentRequest({
          country: 'US',
          currency: 'usd',
          total: {
            label: `Familo ${t(planDetails?.description)} plan`,
            amount: Math.round(planDetails?.periodPrice * 100),
          },
          displayItems: [
            {
              label: `Familo ${t(planDetails?.description)} plan`,
              amount: Math.round(planDetails?.periodPrice * 100),
            },
          ],
          requestPayerName: true,
          requestPayerEmail: true,
        });

        pr.canMakePayment().then((result) => {
          if (result && cardFormVisited) {
            setPaymentRequest(pr);
          }
        });

        pr.on('paymentmethod', async (e) => {
          const successPayment = () => e.complete('success');
          const failedPayment = () => e.complete('fail');

          onPurchaseClick(e, successPayment, failedPayment);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripe.current, planDetails, cardFormVisited]);

  const onPurchaseClick = (
    paymentMethod = null,
    successPayment = null,
    failedPayment = null
  ) => {
    const paymentMethodName = paymentMethod?.walletName || "Stripe";
    const paymentMethodObj = paymentMethod
      ? {
          methodName: paymentMethodName,
          stripeNonce: paymentMethod.paymentMethod.id,
        }
      : null;
    dispatch(
      sendMainPurchaseRequest(
        paymentMethodObj,
        stripe.current,
        card.current,
        name,
        plan,
        token,
        null,
        null,
        userUuid,
        successPayment,
        failedPayment
      )
    );
  };

  useEffect(() => {
    if (isSuccess) {
      history.push("/success");
    }
  }, [isSuccess, history]);

  const STORES = [
    {
      name: "Google Play",
      event: "googlePlayTapped",
      icon: "../assets/google-play.svg",
      grade: 4.5,
      rating: "412K",
    },
    {
      name: "App Store",
      event: "appleStoreTapped",
      icon: "../assets/app-store.svg",
      grade: 4.6,
      rating: "36.9K",
    },
  ];

  const renderStoreItem = (i, index) => {
    return (
      <Store key={index} img={i.icon}>
        <StoreRating>{i.grade}</StoreRating>
        <Stars />
        <StoreRatingsCount>
          {i.rating} {t("ratings")}
        </StoreRatingsCount>
      </Store>
    );
  };

  const supportMail = "support@familo.net";

  const onLinkClick = ({ target: { id } }) => {
    const isTerms = id === "terms-link";
    const isSupport = id === "support-link";
    const links = {
      "terms-link":
        "https://www.familo.net/en/assets/Terms_and_Conditions_Familonet.pdf",
      "support-link": `mailto:${supportMail}`,
    };

    if (!(isTerms || isSupport)) return;
    window.open(links[id]);
  };

  const onBackClick = () => {
    dispatch(setShowCheckout(false))
    dispatch(setCardholderName(''))
    dispatch(setPlan(defaultPlanId))
    card.current.clear()
    expiration.current.clear()
    cvc.current.clear()
    cardholder.current.value = ''
  };

  return (
    <>
      <MainHeadline customMargin={"0 0 24px 0"}>
        <BackButton
          type="button"
          id={"come-back-button"}
          onClick={onBackClick}
        >
          <svg
            width="11"
            height="18"
            viewBox="0 0 11 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 8.90039C0 9.15674 0.0922852 9.38232 0.287109 9.57715L8.41846 17.5239C8.59277 17.7085 8.81836 17.8008 9.08496 17.8008C9.61816 17.8008 10.0283 17.4009 10.0283 16.8677C10.0283 16.6011 9.91553 16.3755 9.75146 16.2012L2.28662 8.90039L9.75146 1.59961C9.91553 1.42529 10.0283 1.18945 10.0283 0.933105C10.0283 0.399902 9.61816 0 9.08496 0C8.81836 0 8.59277 0.0922852 8.41846 0.266602L0.287109 8.22363C0.0922852 8.4082 0 8.64404 0 8.90039Z"
              fill="#323232"
            />
          </svg>
        </BackButton>
        {t("checkout")}
      </MainHeadline>
      {paymentRequest && (
        <Elements stripe={stripePromise}>
          <PaymentRequestContainer>
            <PaymentRequestButtonElement
              options={{
                paymentRequest,
                style: {
                  paymentRequestButton: {
                    height: "56px",
                    theme: "dark",
                    type: "default",
                  },
                },
              }}
            />
          </PaymentRequestContainer>
        </Elements>
      )}
      {load && (
        <Stripe
          card={card.current}
          expiration={expiration.current}
          cvc={cvc.current}
          cardholder={cardholder}
          renderBrand={renderBrand}
        />
      )}
      <CardIconsList>{CARD_BRANDS.map(renderBrand)}</CardIconsList>
      <UIButton onClick={() => onPurchaseClick(null)} disabled={!isFormValid} />
      <StoresWrapper>
        <StoreButtons>{STORES.map(renderStoreItem)}</StoreButtons>
        {planDetails && (
          <Disclaimer
            dangerouslySetInnerHTML={{
              __html: t(isFreeTrialPlan ? "short_disclaimer" : "disclaimer", {
                nowPrice: `$${planDetails.periodPrice}`,
                disclaimer: t(planDetails.disclaimer),
                fullPrice: `$${planDetails.price}`,
                mail: supportMail,
              }),
            }}
            onClick={(e) => onLinkClick(e)}
          />
        )}
      </StoresWrapper>
      {isStage && (
        <input
          type="hidden"
          id="test-skip-checkout"
          onClick={() => history.push("/success")}
        />
      )}
      {showError && (
        <ErrorScreen
          card={card}
          expiration={expiration}
          cvc={cvc}
          inputRef={cardholder}
        />
      )}
    </>
  );
};

export default CheckoutScreen;
