import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import CreatingProfileUI from '../../UI/CreatingProfileUI'
import { appendLinksList } from '../../helper/utils'
import MainHeadline from '../../UI/MainHeadline';
import MainDescription from '../../UI/MainDescription';


const CreatingProfile = ({ history }) => {
    const { t } = useTranslation();

    useEffect(() => {
        appendLinksList('plans');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const doneCallback = () => history.push('/checkout');

    return (
        <>
            <div>
                <MainHeadline customStyles={'margin-top: min(32px,3vh);'}>{t('creating_your_profile')}</MainHeadline>
                <MainDescription>{t('creating_description')}</MainDescription>
            </div>
            <CreatingProfileUI doneCallback={doneCallback} />
        </>
    )
};

export default CreatingProfile