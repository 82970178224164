import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { appendLink } from "../../helper/utils";
import MainHeadline from "../../UI/MainHeadline";
import MainDescription from "../../UI/MainDescription";
import UIButton from "../../UI/UIButton";

const Wrapper = styled.div`
  flex-grow: 1;
  max-height: 500px;
  display: flex;
  flex-direction: column;
`;

const FeatureImg = styled.div`
  flex-grow: 1;
  position: relative;
  margin-bottom: 35px;

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 0 0 48px 48px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    ${({ pageName }) =>
      pageName &&
      `background: url(../assets/screens/${pageName}.png) center bottom / contain no-repeat;`}
  }
`;

const FeaturesContainer = ({
  history,
  pageName,
  nextUrl,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    const image = (nextUrl === 'creating-your-profile')
            ? '../assets/checkmark-circle.svg'
            : `../assets/screens/${nextUrl}.png`;
    appendLink(image);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Wrapper>
        <FeatureImg pageName={pageName} />
        <MainHeadline customMargin={'0 0 12px 0'}>{t(pageName)}</MainHeadline>
        <MainDescription>{t(`${pageName}_description`)}</MainDescription>
      </Wrapper>
      <UIButton
        small
        onClick={() => {
          history.push(nextUrl);
        }}
        customMargin={"30px 0 0"}
      />
    </>
  );
};

export default FeaturesContainer;
