import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import useDebounce from '../../helper/useDebounce';
import {selectShowPaymentError, setCardholderName, setFormValidity} from './checkoutSlice';
import {setError} from '../error/errorSlice';
import {fontSize, wh} from '../../mixins';
import { selectShowCheckout } from '../plans/plansSlice';


const isArabic = navigator.language.startsWith('ar');
const textAlign = isArabic ? 'right' : 'left';

const StyledWrapper = styled.div`
    position: relative;
    z-index: 10;
    margin: 0;

    & > * {
        position: relative;
        z-index: 10;
    }
`;

const CardHeadline = styled.div`
    margin-bottom: 12px;
    padding-top: 10px;
    position: relative;
    
    &:after {
        content: '';
        display: block;
        ${wh('74px','25px')};
        background: url('../assets/secure.png') center / contain no-repeat;
        position: absolute;
        right: 0;
        bottom: 0;
    }
`;

const CardTitle = styled.div`
    font-weight: 700;
    font-size: 16px;
    line-height: 1;
    text-align: left;
    letter-spacing: -0.005em;
    color: #323232;
`;

const StyledCard = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: ${isArabic ? 'flex-end' : 'flex-start'};
    position: relative;
    background-color: #fff;
    z-index: 10;
    
    & > * {
        position: relative;
        z-index: 10;
        
        &:not(:last-child) {
            margin-bottom: 12px;
        }
    }
`;

const StyledFieldWrapper = styled.div`
    width: 100%;
    min-height: 56px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #FFFFFF;
    border: 1px solid #D4D4D4;
    border-radius: 8px;
    padding: 16px 12px;
`;

const StyledRow = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    
    & > div {
        flex: 1 0 40%;
        
        &:not(:last-child) {
            margin-right: 20px;
        }
    }
`;

const StyledInput = styled.input`
    ${wh('100%', '22px')};
    ${fontSize(16)};
    line-height: 22px;
    color: #323232;
    padding: 0;
    background-color: transparent;
    border: none;
    text-align: ${textAlign};
    
    &::-webkit-credit-card-auto-fill-button {
        visibility: hidden;
        display: none !important;
        pointer-events: none;
        position: absolute;
        right: 0;
    }
    
    &::placeholder {
        font-weight: 400;
        ${fontSize(16)};
        line-height: 22px;
        color: #909090;
    }
    
    &:focus,
    &:hover,
    &:active {
        outline: none;
    }
`;

const StyledBrandIcon = styled.span`
    position: absolute;
    right: 16px;
    left: 16px;
    ${textAlign}: auto;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
`;


const Stripe = ({card, cvc, expiration, cardholder, renderBrand}) => {
    const {t} = useTranslation();
    const [cardBrand, setCardBrand] = useState('');
    const [name, setName] = useState('');
    const [isCvv, setIsCvv] = useState(null);
    const [isExp, setIsExp] = useState(null);
    const [isCard, setIsCard] = useState(null);
    const [err, setErr] = useState('');
    const [isValid, setIsValid] = useState(false);
    const dispatch = useDispatch();
    const debouncedName = useDebounce(name, 500);
    const debouncedErr = useDebounce(err, 500);
    const debouncedValidity = useDebounce(isValid, 500);
    const showCheckout = useSelector(selectShowCheckout)
    const showError = useSelector(selectShowPaymentError);
    card.on('ready', () => {
        document.querySelectorAll('.__PrivateStripeElement-input').forEach(el => {
            el.setAttribute('inputmode', 'numeric');
        });
    });

    useEffect(() => {
        if (showCheckout || !showError) {
            card.focus()
        }
    },[showCheckout, showError, card])

    card.on('change', event => {
        if (event.brand && (event.brand !== 'unknown') && (event.brand !== cardBrand)) {
            setCardBrand(event.brand);
        }
        if (event.empty) {
            setCardBrand('')
        }
        event.complete && expiration.focus();
        fieldEventHandler(event, setIsCard);
    });

    expiration.on('change', event => {
        event.complete && cvc.focus();
        fieldEventHandler(event, setIsExp);
    });

    cvc.on('change', event => {
        event.complete && cardholder.current?.focus();
        fieldEventHandler(event, setIsCvv);
    });

    useEffect(() => {
        card.mount('#card-number-element');
        expiration.mount('#card-expiry-element');
        cvc.mount('#card-cvc-element');
    }, [card, cvc, expiration]);

    const fieldEventHandler = (evt, setValidity) => {
        if (evt.error) {
            setErr(evt.error.message);
        }
        setValidity(evt.complete);
    };

    useEffect(() => {
        if (isCard && isCvv && isExp) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    }, [isCard, isCvv, isExp, dispatch]);

    useEffect(() => {
        if (debouncedName) {
            dispatch(setCardholderName(debouncedName));
        }
    }, [debouncedName, dispatch]);

    useEffect(() => {
        if (debouncedErr) {
            dispatch(setError({show: true, type: 'cardValidation', text: debouncedErr}));
        }
    }, [debouncedErr, dispatch]);

    useEffect(() => {
        dispatch(setFormValidity(debouncedValidity));
    }, [debouncedValidity, dispatch]);

    return (
        <StyledWrapper>
            <CardHeadline>
                <CardTitle>{t('checkout_with_card')}</CardTitle>
            </CardHeadline>
            <StyledCard>
                <StyledFieldWrapper>
                    <div id={'card-number-element'} />
                    <StyledBrandIcon>{renderBrand(cardBrand)}</StyledBrandIcon>
                </StyledFieldWrapper>
                <StyledRow>
                    <StyledFieldWrapper>
                        <div id={'card-expiry-element'} />
                    </StyledFieldWrapper>
                    <StyledFieldWrapper>
                        <div id={'card-cvc-element'} />
                    </StyledFieldWrapper>
                </StyledRow>
                <StyledFieldWrapper>
                    <StyledInput ref={cardholder} id={'cardholder-name-element'} onChange={e => setName(e.target.value)} type='text' autocomplete='cc-name' placeholder={t('name_on_card')}/>
                </StyledFieldWrapper>
            </StyledCard>
        </StyledWrapper>
    );
};


export default Stripe;
