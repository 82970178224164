import { css, keyframes } from 'styled-components'

const fadeIn = keyframes`
 0% { opacity: 0; }
 100% { opacity: 1; }
`;
export const fadeInAnimation = css`
  animation-name: ${fadeIn};
  animation-duration: 0.8s;
`;
export const flexButton = (w = 'auto', h) => `
    ${wh(w, h)}
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;


export const tabletMinWidth = 744;
export const desktopMinWidth = 1280;

export const fontSize = (size, base = 16) => `
    font-size: ${size}px;
    font-size: calc(${size / base} * 1rem);
`;

export const wh = (w, h = w) => `
    width: ${w};
    height: ${h};
`;