import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { selectEmail } from "../signup/signupSlice";
import { fontSize, wh } from "../../mixins";
import { Logo } from "../../UI/Logo";
import UIButton from "../../UI/UIButton";
import { isMobile } from "react-device-detect";

const STORE_LINK = process.env.REACT_APP_PREMIUM_LINK;

const StyledWrapper = styled.div`
  & > * {
    margin: 0 auto;
  }
`;

const StyledLogo = styled.div`
  ${wh("68px")};
  background: linear-gradient(134.58deg, #ffa361 -0.33%, #eb5b25 100.24%);
  border-radius: 20px;
  margin-bottom: 32px;
  position: relative;

  svg {
    ${wh("40px", "50px")};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const Title = styled.h1`
  font-weight: 600;
  ${fontSize(24)};
  line-height: 28px;
  text-align: center;
  color: #323232;
  margin: 0 0 24px 0;
`;

const Instructions = styled.ol`
  margin-bottom: 24px;

  li {
    font-weight: 400;
    ${fontSize(16)};
    line-height: 19px;
    text-align: center;
    color: #323232;
    list-style: inside decimal;
    word-break: break-word;
    overflow-wrap: break-word;

    &:not(:last-child) {
      margin-bottom: 11px;
    }
  }
`;

const QRCode = styled.div`
  aspect-ratio: 1/1;
  background: url("./assets/qr_code.png") center / contain no-repeat;
`;

const SuccessScreen = () => {
  const { t } = useTranslation();
  const email = useSelector(selectEmail);

  return (
    <>
      <div />
      <StyledWrapper>
        <StyledLogo>
          <Logo invert={true} />
        </StyledLogo>
        <Title>{t("your_plans_awaits")}</Title>
        <Instructions>
          <li>{t("download_app")}</li>
          <li>
            {t("sign_in_with")} {decodeURIComponent(email)}
          </li>
          <li>{t("enjoy_membership")}</li>
        </Instructions>
      </StyledWrapper>
      {isMobile ? (
        <UIButton
          onClick={() => {
            setTimeout(() => window.location.assign(`${STORE_LINK}`), 1000);
          }}
        />
      ) : (
        <QRCode />
      )}
      <input type="hidden" id="end-of-onboarding" />
    </>
  );
};

export default SuccessScreen;
