import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import MainHeadline from "../../UI/MainHeadline";
import MainDescription from "../../UI/MainDescription";
import UIButton from "../../UI/UIButton";

const StyledImg = styled.div`
  position: relative;
  flex-grow: 1;
  max-height: 380px;
  margin-top: -100px;
  margin-bottom: 20px;

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: url("../assets/screens/welcome.png") center top / 100% no-repeat;
  }
`;

const StyledAppName = styled.div`
  font-weight: 800;
  font-size: 48px;
  color: #F58A3C;
  line-height: 100%;
  margin-bottom: 8px;
`

const BottomWrapper = styled.div`
  width: 100%;
  position: relative;

  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 340px;
    background: linear-gradient(
      180.21deg,
      rgba(255, 255, 255, 0) 19.86%,
      #ffffff 44.65%
    );
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
  }
`;

const Welcome = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <StyledImg />
      <BottomWrapper>
        <MainHeadline customStyles={'font-size:24px; line-height: 29px;'} customMargin={"0 0 4px 0"}>{t("welcome_to")}</MainHeadline>
        <StyledAppName>{t("familo")}</StyledAppName>
        <MainDescription>{t("keep_your_family")}</MainDescription>
        
      </BottomWrapper>
      <UIButton
          small
          isStickyBtn
          onClick={() => {
            props.history.push("/name");
          }}
        />
    </>
  );
};

export default Welcome;
