import React, {useEffect, Suspense} from 'react';
import {BrowserRouter, Route} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import './i18n';
import Cookies from 'js-cookie';
import Router from './Router';
import {setUserToken, setUserUuid} from './features/signup/signupSlice';
import {selectActivePage} from './features/ui/uiSlice';
import { v4 as uuidv4 } from 'uuid';
import GlobalStyle from './globalStyles';
import UIAlert from './UI/Alert';
import Loader from './UI/Loader';
import Layout from './UI/Layout';
import ProgrBar from './UI/ProgrBar';


const App = () => {
    const dispatch = useDispatch();
    const activePage = useSelector(selectActivePage);

    useEffect(() => {
        const setDynamicVH = () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };
        setTimeout(() => {
            setDynamicVH();
            window.addEventListener('resize', setDynamicVH, false);
        },1000);
        return () => window.removeEventListener('resize', setDynamicVH, false);
    }, []);

    useEffect(() => {
        let userUuid = null,
            cookieUuid = Cookies.get('userUuid');

        if (cookieUuid) {
            userUuid = cookieUuid;
        } else {
            userUuid = uuidv4();
            Cookies.set('userUuid', userUuid, {expires: 365});
        }

        dispatch(setUserUuid(userUuid));
    },[dispatch]);

    useEffect(() => {
        const storageToken = window.sessionStorage?.getItem('access_token');

        if (storageToken) {
            dispatch(setUserToken(storageToken))
        }
    }, [dispatch]);

    return (
        <Suspense fallback={<Loader />}>
            <GlobalStyle/>
            <Loader showMessage={true}/>
            <Layout activePage={activePage}>
                <ProgrBar />
                <BrowserRouter>
                    <Route path={['/']} component={Router}/>
                </BrowserRouter>
            </Layout>
            <UIAlert/>
        </Suspense>
    )
};

export default App;
