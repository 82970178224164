import {configureStore} from '@reduxjs/toolkit';
import {combineReducers} from 'redux';
import thunk from 'redux-thunk';
import sessionStorage from 'redux-persist/lib/storage/session';
import { persistReducer, createMigrate } from 'redux-persist';
import signupReducer from '../features/signup/signupSlice';
import familyReducer from '../features/family/familySlice';
import errorReducer from '../features/error/errorSlice';
import loaderReducer from '../features/loader/loaderSlice';
import plansReducer from '../features/plans/plansSlice';
import checkoutReducer from '../features/checkout/checkoutSlice';
import uiReducer from '../features/ui/uiSlice';


const MIGRATION_DEBUG = false;

const migrations = {
    0: (state) => ({
        ...state,
    })
};

const reducers = combineReducers({
    error: errorReducer,
    loader: loaderReducer,
    checkout: checkoutReducer,
    plans: plansReducer,
    ui: uiReducer,
    signup: signupReducer,
    family: familyReducer
});

const persistConfig = {
    key: 'root',
    storage: sessionStorage,
    whitelist: ['plans', 'ui', 'signup', 'family'],
    version: 0,
    migrate: createMigrate(migrations, {debug: MIGRATION_DEBUG})
};

const persistedReducer = persistReducer(persistConfig, reducers);

export default configureStore({
        reducer: persistedReducer,
        devTools: process.env.REACT_APP_ENV === 'stage',
        middleware: [thunk]
    }
);