import { useEffect, useState } from 'react';


const useViewportParams = () => {
    const [params, setParams] = useState('');

    useEffect(() => {
        const setStickyStyles = (e) => {
            const { height } = e.target;
            setTimeout(() => setParams(`
                height: ${height-32}px;
                flex-grow: 0;
            `),1)
        };

        window?.visualViewport?.addEventListener("resize", setStickyStyles);
        window?.visualViewport?.addEventListener("scroll", setStickyStyles);

        return () => {
            window?.visualViewport?.removeEventListener("resize", setStickyStyles);
            window?.visualViewport?.removeEventListener("scroll", setStickyStyles);
        };
    }, []);

    return params;
};

export default useViewportParams;