import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useSelector } from 'react-redux';
import { selectStripePublishKey } from '../features/signup/signupSlice';

const withStripeInit = (WrappedComponent) => {
  return (props) => {
    const stripePk = useSelector(selectStripePublishKey);
    const [stripePromise, setStripePromise] = useState(null);

    useEffect(() => {
      if (!stripePromise) {
        setStripePromise(loadStripe(stripePk));
      }
    }, [stripePk, stripePromise]);

    return <WrappedComponent {...props} stripePromise={stripePromise} />;
  };
};

export default withStripeInit;