import { createSlice } from '@reduxjs/toolkit'
import { fetchWrapper } from '../../helper/utils'
import { setError } from '../error/errorSlice'
import { setLoader } from '../loader/loaderSlice'


const {
  REACT_APP_API_URL,
  REACT_APP_STRIPE_PK,
  REACT_APP_STRIPE_NAME,
} = process.env;

export const signupSlice = createSlice({
    name: 'signup',
    initialState: {
        email: '',
        userName: '',
        isValid: false,
        token: null,
        foUserID: null,
        isSuccess: null,
        userUuid: null,
        stripePublishKey: REACT_APP_STRIPE_PK,
        stripeAccountName: REACT_APP_STRIPE_NAME,
    },
    reducers: {
        setEmail: (state, action) => {
            state.email = encodeURIComponent(action.payload.email);
            state.isValid = action.payload.isValid
        },
        setRequestResult: (state, action) => {
            const {
                access_token,
                entity: { familo_user_id, name, registration_source, email },
            } = action.payload;
            state.token = access_token;
            state.isSuccess = true;
            state.foUserID = familo_user_id;
            state.userName = name === email ? '' : name;
            state.email = encodeURIComponent(email);
            state.registrationSource = registration_source
        },
        setUserToken: (state, action) => {
            state.token = action.payload
        },
        setUserUuid: (state, action) => {
            state.userUuid = action.payload
        },
        setStripeData: (state, action) => {
            state.stripePublishKey = action.payload.stripePublishKey;
            state.stripeAccountName = action.payload.stripeAccountName
        },
    },
});

export const {
    setEmail,
    setRequestResult,
    setUserToken,
    setUserUuid,
    setStripeData,
} = signupSlice.actions;

export const sendAPIRequest = (email, name) => (dispatch) => {
    dispatch(setLoader(true));

    const params = {
        registration_source: 'email',
        name: name || email,
        email: email,
    };

    fetchWrapper(`${REACT_APP_API_URL}/signup`, { body: params })
        .then((data) => {
            window.sessionStorage.setItem('access_token', data.data.access_token);
            dispatch(setRequestResult(data.data));
            dispatch(getPaymentSettings(data.data.access_token));
        })
        .catch((error) => {
            dispatch(
                setError({
                    show: true,
                    text: error.errors?.info?.email || 'Failed to create an account',
                    type: 'signup',
                })
            )
        // dispatch(setRequestResult({isSuccess: false}));
        })
        .then(() => dispatch(setLoader(false)))
};

const getPaymentSettings = (token) => (dispatch) => {
    let stripeParams = {
        stripePublishKey: REACT_APP_STRIPE_PK,
        stripeAccountName: REACT_APP_STRIPE_NAME,
    };

    fetchWrapper(`${REACT_APP_API_URL}/users/me/payments-settings`, {headers: { Authorization: `Bearer ${token}` },})
        .then((data) => {
            let { stripe_publishable_key, stripe_account_name } = data;
            if (stripe_publishable_key) {
                stripeParams = {
                    stripePublishKey: stripe_publishable_key,
                    stripeAccountName: stripe_account_name,
                }
            }
        })
        .catch((error) => console.log(error))
        .then(() => {
            dispatch(setStripeData(stripeParams))
        })
};

export const selectEmail = (state) => state.signup.email;
export const selectValidity = (state) => state.signup.isValid;
export const selectSuccess = (state) => state.signup.isSuccess;
export const selectToken = (state) => state.signup.token;
export const selectUserID = (state) => state.signup.foUserID;
export const selectUserName = (state) => state.signup.userName;
export const selectUserUuid = (state) => state.signup.userUuid;
export const selectStripePublishKey = (state) => state.signup.stripePublishKey;
export const selectStripeAccountName = (state) => state.signup.stripeAccountName;

export default signupSlice.reducer