import styled from 'styled-components';
import { fontSize } from '../mixins';


const Headline = styled.h1`
    font-weight: 600;
    ${fontSize(32)};
    font-size: clamp(1.7rem,8.5vw,2rem);
    line-height: 38px;
    letter-spacing: -0.03em;
    color: #323232;
    margin: ${({customMargin}) => customMargin || '0 0 8px 0'};
    white-space: break-spaces;
    position: relative;
    ${({customStyles}) => customStyles};
`;

const MainHeadline = ({children, customMargin, customStyles = ''}) => <Headline customMargin={customMargin} customStyles={customStyles}>{children}</Headline>;

export default MainHeadline;