import styled from "styled-components";
import { fontSize, desktopMinWidth } from "../mixins";

const Button = styled.button`
  width: 100%;
  max-width: 327px;
  background-color: ${({ customColor }) => customColor || "#F58A3C"};
  border-radius: 12px;
  border: none;
  padding: ${({ small }) => (small ? "14px 0" : "20px 0")};
  margin: ${({ customMargin }) => customMargin || "24px 0 0"};
  font-weight: 600;
  ${fontSize(16)};
  line-height: 18px;
  color: #ffffff;
  position: relative;
  z-index: ${({ zIndex }) => zIndex || "initial"};
  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
  transition: background-color 0.3s;
  ${({ isStickyBtn }) =>
    isStickyBtn &&
    `
        @media screen and (max-width: 450px) {
            position: fixed;
            bottom: 24px;
        }
    `}

  &:focus {
    outline: none;
  }

  ${({ customColor, disabled }) =>
    !customColor &&
    !disabled &&
    `
        @media screen and (min-width: ${desktopMinWidth}px) {
            &:hover {
                background-color: #F99B57;
            }
        }
    
        &:active {
            background-color: #EA7825;
        }
    `}
`;

const UIButton = ({
  onClick,
  children,
  customText,
  disabled,
  customColor,
  zIndex,
  customId,
  customMargin,
  small = false,
  isStickyBtn = false
}) => {
  const customInner = customText || "Continue";
  const onClickFunc = () => {
    if (disabled) return;
    onClick();
  };

  return (
    <Button
      onClick={onClickFunc}
      disabled={disabled}
      customColor={customColor}
      customMargin={customMargin}
      id={customId || "continue-button"}
      small={small}
      zIndex={zIndex}
      isStickyBtn={isStickyBtn}
    >
      {children || customInner}
    </Button>
  );
};

export default UIButton;
