import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import useViewportParams from '../../helper/useViewportHeight'
import {
    selectEmail,
    selectSuccess,
    selectValidity,
    sendAPIRequest,
} from './signupSlice'
import {selectName} from '../family/familySlice';
import { fontSize, tabletMinWidth } from '../../mixins'
import SignupInput from './SignupInput'
import UIButton from '../../UI/UIButton'
import MainHeadline from '../../UI/MainHeadline'
import MainDescription from '../../UI/MainDescription'
import { appendLink } from '../../helper/utils'

const SignupWrapper = styled.div`
    @media screen and (max-width: ${tabletMinWidth}px) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        transition: height 100ms;
        ${({focused,viewportParams}) => !(focused && viewportParams) && 'flex-grow: 1;'}
        ${({viewportParams}) => viewportParams}
    }
`;

const FormWrapper = styled.div`
    padding: 40px 16px 26px;
    background: #FFF1E8;
    border-radius: 48px;
    
    @media screen and (max-width: 350px) {
        padding: 30px 8px 20px;
        border-radius: 30px;
    }
`;

const StyledNote = styled.p`
    ${fontSize(14)};
    line-height: 17px;
    color: #ADADAD;
    margin-top: 16px;
`;

const SignupScreen = ({ history }) => {
    const { t } = useTranslation();
    const isValid = useSelector(selectValidity);
    const email = useSelector(selectEmail);
    const name = useSelector(selectName);
    const isSuccess = useSelector(selectSuccess);
    const dispatch = useDispatch();
    const [focused,setFocused] = useState(false);
    const viewportParams = useViewportParams();

    const onBtnClick = () => {
        dispatch(sendAPIRequest(decodeURIComponent(email),decodeURIComponent(name)))
    };

    useEffect(() => {
        appendLink("../assets/screens/check-ins.png");
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    useEffect(() => {
        if (isSuccess) {
            history.push('/check-ins')
        }
    }, [isSuccess, history, email]);

    const setFocusedWithDelay = set => setTimeout(() => setFocused(set),100);

    return (
        <SignupWrapper focused={focused} viewportParams={viewportParams} >
            <div>
                <FormWrapper>
                    <MainHeadline>{t('what_is_your_email')}</MainHeadline>
                    <MainDescription customMargin={'0 0 32px 0'}>{t('enter_your_email')}</MainDescription>
                    <SignupInput setFocused={setFocusedWithDelay} />
                </FormWrapper>
                <StyledNote>{t('sign_up_note')}</StyledNote>
            </div>
            <UIButton onClick={onBtnClick} disabled={!isValid} />
        </SignupWrapper>
    )
};

export default SignupScreen
