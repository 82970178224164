import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {fontSize, flexButton} from '../mixins';


const TimerContainer = styled.div`
    border-radius: 12px;
    margin: 24px 0 36px;
`;

const TimerTitle = styled.div`
    font-weight: 600;
    ${fontSize(20)};
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #323232;
    margin-bottom: 8px;
`;

const TimeWrapper = styled.div`
    ${flexButton()};
`;

const TimeItem = styled.div`
    background: rgba(255,163,97,.15);
    border-radius: 8px;
    padding:  3px 5px;
    font-weight: 800;
    ${fontSize(20)};
    line-height: 27px;
    text-align: right;
    color: #FFA361;
    position: relative;
    
    span {
        font-weight: 900;
        ${fontSize(12)};
        line-height: 16px;
    }
    
    &:not(:last-child) {
        margin-right: 15px;
        &:after {
            content: ':';
            display: block;
            font-weight: 500;
            ${fontSize(20)};
            line-height: 27px;
            position: absolute;
            top: 0;
            left: calc(100% + 4px);
        }
    }
`;

const checkNumber = (value) => {
    value = value.toString();
    if (value.length === 1) {
        return '0' + value;
    }
    return value;
};

const timer = () => {
    const dateNow = new Date();
    let day = dateNow.getDate() % 3;
    const hour = 23 - dateNow.getHours();
    const minutes = 59 - dateNow.getMinutes();
    const second = 59 - dateNow.getSeconds();
    if (day === 1) {
        day = day + 2;
    }

    return {
        day: checkNumber(day),
        hour: checkNumber(hour),
        minutes: checkNumber(minutes),
        second: checkNumber(second),
    };
};

const Timer = () => {
    const {t} = useTranslation();

    const [date, setDate] = useState({
        day: '00',
        hour: '00',
        minutes: '00',
        second: '00',
    });

    useEffect(() => {
        const interval = setInterval(() => {
            const newDate = timer();
            setDate({
                ...date,
                ...newDate
            });
        }, 1000);
        return () => clearInterval(interval);
    });

    return (
        <TimerContainer>
            <TimerTitle>{t('limited_offer')}:</TimerTitle>
            <TimeWrapper>
                <TimeItem>{date.day}<span>{t('days')}</span></TimeItem>
                <TimeItem>{date.hour}<span>{t('hours')}</span></TimeItem>
                <TimeItem>{date.minutes}<span>{t('minutes')}</span></TimeItem>
                <TimeItem>{date.second}<span>{t('seconds')}</span></TimeItem>
            </TimeWrapper>
        </TimerContainer>
    );
};

export default Timer;
