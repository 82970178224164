import styled from 'styled-components'
import { tabletMinWidth } from '../mixins';


const StyledLayout = styled.div`
    width: 100%;
    min-height: 100%;
    max-width: 376px;
    margin: 0 auto;
    padding: ${({ activePage }) => (activePage === '' ? '0px' : '16px 20px')};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: ${({ activePage }) => (activePage === '' ? 'center' : 'space-between')};
    text-align: center;
    
    @media screen and (min-width: ${tabletMinWidth}px) {
        justify-content: center;
    }
    
    @media screen and (max-width: 350px) {
        padding: 16px;
    }
    
    & > * {
        width: 100%;
    } 
`;

const Layout = ({ children, activePage }) => (
    <StyledLayout activePage={activePage}>
        {children}
    </StyledLayout>
);

export default Layout;