export const fetchWrapper = (url, {body, ...customConfig} = {}) => {
    const headers = {'Content-Type': 'application/json'};

    const config = {
        method: body ? 'POST' : 'GET',
        ...customConfig,
        headers: {
            ...headers,
            ...customConfig.headers,
        },
    };

    if (body) {
        config.body = JSON.stringify(body)
    }

    return window
        .fetch(url, config)
        .then(async response => {
            const data = await response.json();
            if (response.ok) {
                return data;
            } else {
                return Promise.reject(data)
            }
        })
};

export const calcPercent = (partialValue, totalValue) => (100 * partialValue) / totalValue;

export const queryParser = (str) => {
    const cutStr = str.slice(1);
    const coupleStr = cutStr.split('&').map(item => item.split('='));
    const obj = coupleStr.reduce((acc, [key, value]) => {
        return {
            ...acc,
            [key.replace('+','_')]: decodeURIComponent(value),
        };
    }, {});
    return obj;
};

const LINKS = {
    plans: [
        '../assets/reviewer1.png',
        '../assets/reviewer2.png',
        '../assets/reviewer3.png',
        '../assets/guarantee-icon.svg',
        '../assets/app-icon.svg',
        '../assets/stars.png',
        '../assets/apple-icon.svg',
        '../assets/android-icon.svg',
    ],
    checkout: [
        '../assets/visa.png',
        '../assets/mastercard.png',
        '../assets/amex.png',
        '../assets/discover.png',
        '../assets/secure.png',
        '../assets/google-play.svg',
        '../assets/app-store.svg',
    ],
};
  
export const appendLink = (href, type = 'prefetch') => {
    const link = document.createElement('link');
    link.setAttribute('rel', type);
    link.setAttribute('href', href);
    link.setAttribute('as', 'image');
    document.head.appendChild(link)
};

export const appendLinksList = type => {
    LINKS[type].map(link => appendLink(link));
};