import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setError, selectError } from '../features/error/errorSlice';
import styled from 'styled-components';
import { wh, fontSize } from '../mixins';


const StyledAlert = styled.div`
  ${wh('90%', 'auto')};
  max-width: 327px;
  min-height: 50px;
  padding: 16px 40px 16px 16px;
  border-radius: 8px;
  background-color: #FF5B5B;
  position: absolute;
  left: 50%;
  top: 6%;
  transform: translate(-50%, -20%);
  ${fontSize(14)};
  line-height: 18px;
  color: #fff;
  z-index: 100;
  ${({ show }) => (show
    ? `
      opacity: 1;
      visibility: visible; 
    `
    : `
      opacity: 0;
      visibility: hidden;
    `
  )};
  transition: 150ms ease;
`;

const CloseAlert = styled.div`
  content: '×';
  display: block;
  ${wh('24px')};
  position: absolute;
  top: 50%;
  right: 16px;
  margin-top: -12px;
  color: #fff;
  line-height: 24px;
  ${fontSize(24)};
  text-align: center;
  cursor: pointer;
`;

const UIAlert = () => {
  const error = useSelector(selectError);
  const dispatch = useDispatch();
  const [clear,setClear] = useState(false);

  const clearError = useCallback(() => dispatch(setError({show: false, text: '', type: ''})), [dispatch]);

  useEffect(() => {
    if (error.show && !clear) {
      var timer = setTimeout(clearError, 3000);
    }
    return () => {
      setClear(false);
      clearTimeout(timer);
    }
  }, [error, clearError, clear]);

  const onCloseClick = () => {
    setClear(true);
    clearError()
  };

  return (
    <StyledAlert show={error.show}>
      <p>{error.text}</p>
      <CloseAlert onClick={onCloseClick}>×</CloseAlert>
    </StyledAlert>
  )
};

export default UIAlert;