import React, { useCallback, useEffect, useRef } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setActivePage } from "./features/ui/uiSlice";
import Welcome from "./features/welcome/Welcome";
import NameScreen from "./features/family/NameScreen";
import SignupScreen from "./features/signup/SignupScreen";
import CreatingProfile from "./features/family/CreatingProfile";
import SuccessScreen from "./features/success/SuccessScreen";
import PlatformSupport from "./features/options/PlatformSupport";
import CheckIn from "./features/options/CheckIn";
import ArSearch from "./features/options/ArSearch";
import SosButton from "./features/options/SosButton";
import PlanCheckout from "./features/PlanCheckout/PlanCheckout";

const Router = ({ history }) => {
  const dispatch = useDispatch();

  const routes = [
    {
      path: ["/"],
      component: Welcome,
    },
    {
      path: "/name",
      component: NameScreen,
    },
    {
      path: "/signup",
      component: SignupScreen,
    },
    {
      path: "/check-ins",
      component: CheckIn,
    },
    {
      path: "/platform-support",
      component: PlatformSupport,
    },
    {
      path: "/ar-search",
      component: ArSearch,
    },
    {
      path: "/sos-button",
      component: SosButton,
    },
    {
      path: "/creating-your-profile",
      component: CreatingProfile,
    },
    {
      path: "/checkout",
      component: PlanCheckout,
    },
    {
      path: "/success",
      component: SuccessScreen,
    },
  ];

  const {
    location: { pathname },
  } = history;

  const locationHistory = useRef([pathname]);

  const setPath = useCallback(
    (path) => {
      dispatch(setActivePage(path.replace("/", "")));
    },
    [dispatch]
  );

  useEffect(() => setPath(pathname), [pathname, dispatch, setPath]);

  useEffect(() => {
    history?.listen((newLocation, action) => {
      let { current } = locationHistory,
        path = newLocation.pathname,
        prevPath = current[current.length - 1];

      setPath(path);

      if (path !== prevPath) {
        if (action === "PUSH") {
          if (!current.includes(path)) {
            window.scrollTo(0, 0);
            locationHistory.current.push(path);
            window.sessionStorage.setItem(
              "locationHistory",
              locationHistory.current
            );
          } else {
            locationHistory.current = current.slice(
              0,
              current.indexOf(path) + 1
            );
            window.sessionStorage.setItem(
              "locationHistory",
              locationHistory.current
            );
          }
        } else {
          path = prevPath;
        }

        history.push({ pathname: path });
      }
    });
  }, [history, setPath]);

  return (
    <Switch>
      {routes.map((route, i) => (
        <Route exact key={i} {...route} />
      ))}
    </Switch>
  );
};

export default withRouter(Router);
