import {createSlice} from '@reduxjs/toolkit';
import {setCheckoutLoader} from '../loader/loaderSlice';
import {getPollingID, sendStripeReq, subscribeToPriceChange} from './fetch';
import {calcPercent} from '../../helper/utils';

export const checkoutSlice = createSlice({
    name: 'checkout',
    initialState: {
        cardholderName: '',
        token: null,
        isSuccess: null,
        isFormValid: null,
        id: null,
        regularPrice: null,
        stripeNonce: '',
        upsellType: 'old',
        upsellData: null,
        showPaymentError: false,
        errorText: '',
        isCardFormShown: false,
    },
    reducers: {
        setCardholderName: (state, action) => {
            state.cardholderName = action.payload;
        },
        setRequestResult: (state, action) => {
            const {isSuccess, id, finalPrice, regularPrice, upsell_type} = action.payload;
            const upsellType = ['old','new'];

            state.isSuccess = isSuccess;
            state.id = id;
            state.finalPrice = finalPrice;
            state.regularPrice = regularPrice;
            state.upsellType = upsellType[upsell_type || 0];
        },
        setUpsellData: (state, action) => {
            state.upsellData = {
                success: action.payload?.isSuccess,
                finalPrice: action.payload?.finalPrice,
                regularPrice: action.payload?.regularPrice
            }
        },
        setDiscountPercentage: (state, action) => {
            const {finalPrice, regularPrice} = action.payload;
            state.discountPercentage = calcPercent((regularPrice - finalPrice), regularPrice);
        },
        setFormValidity: (state, action) => {
            state.isFormValid = action.payload;
        },
        setStripeNonce: (state, action) => {
            state.stripeNonce = action.payload;
        },
        setShowPaymentError: (state, action) => {
            const {show,text} = action.payload;
            state.showPaymentError = show;
            state.errorText = text;
        },
        setIfCardFormShown: (state, action) => {
            state.isCardFormShown = action.payload;
        },
    },
});

export const {
    setCardholderName,
    setStripeNonce,
    setRequestResult,
    setDiscountPercentage,
    setFormValidity,
    setUpsellData,
    setShowPaymentError,
    setIfCardFormShown,
} = checkoutSlice.actions;


const sendServerReq = async (stripe, paymentMethodObj, plan, token, analyticsParams, eventsData, onError, onSuccess, eventId, upsell, payment_intent_id) => {
    const {stripeNonce, methodName} = paymentMethodObj;
    const pollingID = await getPollingID(stripeNonce, plan, token, analyticsParams, eventsData, onError, eventId, upsell, payment_intent_id);
    const purchaseRequest = await subscribeToPriceChange(pollingID);
    const { status, errors, data } = purchaseRequest;

    if (status) {
        if (status === 'requires_action' && data && data.payment_intent_secret) {
            const cardPaymentSecret = data.payment_intent_secret;
            const cardPaymentData = methodName ? {payment_method: stripeNonce} : {setup_future_usage: 'off_session'};
            const cardPaymentOptions = methodName ? {handleActions: false} : {};

            const {error: errorAction, paymentIntent} = await stripe.confirmCardPayment(cardPaymentSecret,cardPaymentData,cardPaymentOptions);

            if (errorAction) {
                if (methodName) {
                    onError(errorAction.message);
                } else {
                    await sendServerReq(stripe, paymentMethodObj, plan, token, analyticsParams, eventsData, onError, onSuccess, eventId, upsell, data.payment_intent_id);
                }
                return;
            } else {
                if (methodName) {
                    if (paymentIntent.status === 'requires_action') {
                        const {error} = await stripe.confirmCardPayment(data.payment_intent_secret);
                        if (error) {
                            onError(error);
                            return
                        }
                    }

                    onSuccess();
                }
            }

            await sendServerReq(stripe, paymentMethodObj, plan, token, analyticsParams, eventsData, onError, onSuccess, eventId, upsell, paymentIntent.id);

        } else {
            const {subscription_id, price_with_discount, regular_price, upsell_type} = purchaseRequest;

            return onSuccess(subscription_id, price_with_discount, regular_price, upsell_type);
        }
    }

    if (errors) {
        if (status === 'requires_action') return;

        onError(errors.messages || 'An error has occurred');
    }
};

export const sendMainPurchaseRequest = (paymentMethodObj = null, stripe, card, name, plan, token, analyticsParams, eventsData, eventId, successPayment, failedPayment) => async dispatch => {
    const onError = (data) => {
        if (failedPayment) failedPayment();

        let txt = data;
        if (typeof txt !== 'string') txt = data.message;
        if (typeof txt !== 'string') {
            txt = 'Payment error';
        }
        dispatch(setShowPaymentError({show: true, text: txt}));
        // dispatch(setError({show: true, text: txt, type: 'payment'}));
    };
    const onSuccess = (id, finalPrice, regularPrice, upsell_type) => {
        if (successPayment) successPayment();
        if (finalPrice) {
            dispatch(setDiscountPercentage({finalPrice, regularPrice}));
        }

        dispatch(setRequestResult({
            isSuccess: true,
            id,
            finalPrice,
            regularPrice,
            upsellType: upsell_type
        }));
    };

    dispatch(setCheckoutLoader({show: true, type: 'checkout'}));

    try {
        if (paymentMethodObj) {
            await sendServerReq(stripe, paymentMethodObj, plan, token, analyticsParams, eventsData, onError, onSuccess, eventId, '', '');
        } else {
            const { paymentMethod, error } = await sendStripeReq(stripe, card, name);
            let stripeNonce = paymentMethod?.id || error?.code;

            if (error) {
                console.error(error.message);
                dispatch(setShowPaymentError({ show: true, text: error.message }));
            } else {
                dispatch(setStripeNonce(stripeNonce));
                await sendServerReq(stripe, { stripeNonce }, plan, token, analyticsParams, eventsData, onError, onSuccess, eventId, '', '');
            }
        }
    } finally {
        dispatch(setCheckoutLoader({ show: false, type: '' }));
    }
};


export const selectSuccess = state => state.checkout.isSuccess;
export const selectName = state => state.checkout.cardholderName;
export const selectFormValidity = state => state.checkout.isFormValid;
// export const selectID = state => state.checkout.id;
// export const selectStripeNonce = state => state.checkout.stripeNonce;
// export const selectUpsellData = state => state.checkout.upsellData;
// export const selectUpsellType = state => state.checkout.upsellType;
export const selectShowPaymentError = state => state.checkout.showPaymentError;
export const selectErrorText = state => state.checkout.errorText;
export const selectIfCardFormShown = (state) => state.checkout.isCardFormShown;

export default checkoutSlice.reducer;
