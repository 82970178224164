import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import useDebounce from "../../helper/useDebounce";
import useViewportParams from "../../helper/useViewportHeight";
import { setName } from "./familySlice";
import { selectUserName } from "../signup/signupSlice";
import { fontSize, wh, tabletMinWidth } from "../../mixins";
import MainHeadline from "../../UI/MainHeadline";
import MainDescription from "../../UI/MainDescription";
import UIButton from "../../UI/UIButton";

const isArabic = navigator.language.startsWith("ar");
const textAlign = isArabic ? "right" : "left";

const NameWrapper = styled.div`
  @media screen and (max-width: ${tabletMinWidth}px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: height 100ms;
    ${({ focused, viewportParams }) =>
      !(focused && viewportParams) && "flex-grow: 1;"}
    ${({ viewportParams }) => viewportParams}
  }
`;

const Input = styled.input`
  ${wh("100%", "56px")};
  border: 1px solid #dee0e6;
  box-sizing: border-box;
  border-radius: 12px;
  background: #ffffff;
  padding: 15px;
  ${fontSize(16)};
  line-height: 26px;
  color: #323232;
  text-align: ${textAlign};

  &::placeholder {
    color: #494e57;
  }

  &:focus,
  &:active {
    outline: none;
    border-color: #f58a3c;
  }
`;

const NameScreen = ({ history }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const name = useSelector(selectUserName);
  const [value, setValue] = useState(name || "");
  const debouncedName = useDebounce(value, 200);
  const viewportParams = useViewportParams();
  const [focused, setFocused] = useState(false);

  useEffect(() => debouncedName && dispatch(setName(debouncedName)));

  const onChangeHandler = (evt) => {
    const {
      target: { value },
    } = evt;
    setValue(value.slice(0, 35));
  };

  return (
    <NameWrapper focused={focused} viewportParams={viewportParams}>
      <div>
        <MainHeadline>{t("what_is_your_name")}</MainHeadline>
        <MainDescription customMargin={"0 0 32px 0"}>
          {t("enter_your_name")}
        </MainDescription>
        <Input
          type="text"
          value={value}
          onChange={onChangeHandler}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          placeholder={t("Name")}
          id={"name-input"}
        />
      </div>
      <UIButton
        onClick={() => {
          history.push("/signup");
        }}
        disabled={!value}
      />
    </NameWrapper>
  );
};

export default NameScreen;
